import { useApiContinents } from '@/composables/useApiContinents';
import { useRoutes } from '@/composables/useRoutes';
import { useUrls } from '@/composables/useUrls';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getContinentByName } = useApiContinents();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE CONTINENT WITH CALL TO SPAPI
    const locationObj = await getContinentByName(propertyTypeObj?.key, urlParams.continent);

    if (locationObj?.id) {
      // CONTINENT SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const continentPageUrlForCurrentLang = useUrl.getContinentPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const continentPagePathForCurrentLang = new URL(continentPageUrlForCurrentLang).pathname.toLowerCase();

      if (continentPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(continentPageUrlForCurrentLang, {
          redirectCode: 301,
          external: true,
        });
      }
    } else {
      // CONTINENT IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Continent '${urlParams.continent}'`;

      let urlForTranslation = urlPath;
      if (!urlParams.propertyType.match(/\.php$/)) {
        urlForTranslation = urlPath.replace(`${urlParams.propertyType}/`, `${propertyTypeObj.slug}/`);
      }

      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlForTranslation),
      });
    }
  }
});
